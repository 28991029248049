<template>
  <div id="topiclocal3">
    <div class="title"><b>*模块导入试题</b></div>
    <main>
      <div class="box">
        <p>上传资源 <span>*</span></p>
        <el-upload
          class="upload-demo"
          accept=".xls, .xlsx"
          action="false"
          drag
          :http-request="upload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">{{ file }}</div>
          <div class="el-upload__tip" slot="tip">
            注意事项：请先<emz style="color: #1c5eff"
              ><span @click="moban()" style="cursor: pointer"
                >下载模板</span
              ></emz
            >，安装板块内容填充试题
          </div>
        </el-upload>
      </div>
      <!-- <img src="../../img/板块图标 - 副本_slices/111.png" alt="" /> -->
      <div class="addtopic" @click="addtopic">添加试题</div>
    </main>
  </div>
</template>
<script>
// import $ from "jquery";
import * as util from "@/unit/network";
export default {
  data() {
    return {
      type: "", // 试题类型
      option: "", // 试题选项
      file: "请选择文件",
      fullscreenLoading: false,
      loading: "",
      files: "",
    };
  },
  mounted() {},
  methods: {
    moban() {
      window.location.href = "http://zy.yunlearning.com/upload/muban.xlsx";
    },
    upload(e) {
      console.log(e);
      this.files = "正在上传";
      this.openFullScreen2();
      let formData = new FormData();
      formData.append("file", e.file);
      util.post_file("/questionImport", formData, (res) => {
        console.log(res);
        if (res.code == 0) {
          this.loading.close();
          this.file = "请选择文件";
          this.$message({
            message: "请选择上传文件",
            type: "warning",
          });
        } else if (res.code == 1) {
          this.file = "上传成功";
          this.loading.close();
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.files = res.data;
          console.log(this.files, "list");
          // localStorage.setItem("mubanlist", JSON.stringify(this.files));
          if (localStorage.getItem("jsonkey" + this.$route.query.dataid)) {
            let arr = localStorage.getItem(
              "jsonkey" + this.$route.query.dataid
            );
            let str = JSON.parse(arr);
            // str.push()
            for (let i = 0; i < this.files.length; i++) {
              str.push(this.files[i]);
            }
            localStorage.setItem(
              "jsonkey" + this.$route.query.dataid,
              JSON.stringify(str)
            );
          } else {
            localStorage.setItem(
              "jsonkey" + this.$route.query.dataid,
              JSON.stringify(this.files)
            );
          }
        }
      });
    },
    openFullScreen2() {
      this.loading = this.$loading({
        lock: true,
        text: "正在上传...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    addtopic() {
      this.$router.push({
        path: "/main/addscene/topicresource/topiclocal",
        query: {
          csid: this.$route.query.csid,
          dataid: this.$route.query.dataid,
          filetype: this.$route.query.filetype,
          muban: 1,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
#topiclocal3 {
  height: 100%;
  main {
    margin-top: 37px;

    margin-left: 58px;
    .box {
      display: flex;
      p {
        margin-right: 13px;
      }
    }
    img {
      margin-left: 81px;
    }
    .addtopic {
      width: 100/96in;
      height: 36/96in;
      background: #1c5eff;
      border-radius: 5px;
      color: #fff;
      line-height: 36/96in;
      text-align: center;
      margin-left: 591px;
      margin-top: 80px;
      cursor: pointer;
    }
  }
  /deep/.el-upload-dragger {
    background-color: #fff;
    border: 0.01rem dashed #d9d9d9;
    border-radius: 0.06rem;
    box-sizing: border-box;
    width: 190/96in;
    height: 110/96in;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  /deep/.el-upload-dragger .el-icon-upload {
    font-size: 31/96in;
    color: #c0c4cc;
    margin: 18px 0 0;
    line-height: 50/96in;
  }
  /deep/.upload-demo {
    // margin-left: 28/96in + 88/96in;
    // margin-top: 38px;
    font-size: 14px;
    .el-upload__text {
      color: #949595;
    }
    .el-upload__tip {
      font-size: 14px;
      font-weight: 400;
      color: #949595;
      margin-bottom: 67px;
    }
  }
  /deep/.el-upload-list .el-upload-list--text {
    width: 684/96in;
  }
}
</style>